import useCheckUserSession from "hooks/useCheckUserSession";
import "./App.css";
import { useEffect } from "react";
import { getLatestFeatureUpdates } from "utils/getLatestFeatureUpdates";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import ProtectedRoute from "components/jkt/global/protectedRoute";
import { Route, Routes } from "react-router-dom";
import AuthorizedRoute from "components/jkt/global/authorizedRoute";
import { jktRoutes } from "routes/jktRoutes";
import { Toolbar } from "@mui/material";
import Footer from "components/jkt/global/footer";
import useVersionChecker from "hooks/useVersionChecker";
import { isProjectPhaseLive } from "constants/connections";
import AlertMessage from "components/mnl/global/alertMessage";
import { parsedClientDetails } from "utils/parsedClientDetails";

function JktApp() {
  // eslint-disable-next-line
  const appTitle = "eRequest Client";
  document.title = appTitle;
  const { autoLogoutUser } = useCheckUserSession();
  const { isUpdateAvailable, checkForLatestAppVersion } = useVersionChecker();
  const { isClientKedoya } = parsedClientDetails();

  useEffect(() => {
    getLatestFeatureUpdates();
  }, []);

  //Automatically logout user after 24hrs if they didn't end their previous session
  useEffect(() => {
    if (isUserLoggedIn()) {
      autoLogoutUser();
    }
  }, [autoLogoutUser]);

  //Conditionally render element wether they are protected or not
  const renderRouteElement = (isRouteProtected, element) => {
    if (isRouteProtected) {
      return <ProtectedRoute>{element}</ProtectedRoute>;
    }
    return element;
  };

  // To make sure that the user always gets the updated version of the web app
  useEffect(() => {
    if (isProjectPhaseLive) {
      checkForLatestAppVersion();
    }
    // eslint-disable-next-line
  }, [isProjectPhaseLive]);

  return (
    <>
      {isUpdateAvailable && isProjectPhaseLive && <AlertMessage />}
      <Routes>
        {jktRoutes
          .filter((data) => {
            if (!isClientKedoya) {
              return data?.permission !== "Administration:ClientAffiliate:Read";
            }
            return data;
          })
          .map((route) => {
            const routeElement = route?.permission ? (
              <AuthorizedRoute permission={route?.permission}>
                {route?.element}
              </AuthorizedRoute>
            ) : (
              route?.element
            );
            return (
              <Route
                key={route?.path}
                path={route?.path}
                element={renderRouteElement(route.isProtected, routeElement)}
              />
            );
          })}
      </Routes>
      <Toolbar />
      <Footer />
    </>
  );
}

export default JktApp;
