import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import decodedUserDetails from "utils/decodedUserDetails";
import { parsedClientDetails } from "utils/parsedClientDetails";

const createHealthcareProvider = createAsyncThunk(
  "healthcareProviders/create-healthcare-provider",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const isProjectLocationJkt =
        process.env.REACT_APP_COUNTRY.toLowerCase() === "jkt";
      const { isClientKedoya } = parsedClientDetails();

      const response = await api.post(URL_HEALTHCARE_PROVIDER, {
        firstName: data.firstName,
        licenseNumber: isProjectLocationJkt ? null : data.licenseNumber,
        licenseExpirationDate: isProjectLocationJkt
          ? null
          : data.licenseExpirationDate,
        code: isClientKedoya ? data?.code : null,
        lastName: data.lastName,
        title: isProjectLocationJkt ? null : data.title,
        specialty: isProjectLocationJkt ? null : data.specialty,
        phoneNumber: isProjectLocationJkt ? null : data.phoneNumber,
        email: isProjectLocationJkt ? null : data.email,
        clientId: clientId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createHealthcareProvider };
