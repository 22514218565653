import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  Zoom,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Popover } from "antd";
import { getHealthcareProvidersLookUp } from "store";
import CreateUpdatePhysicianModal from "./createUpdatePhysicianModal";
import decodedUserDetails from "utils/decodedUserDetails";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangePhysicianFields } from "store/slice/physicianInputSlice";
import truncateString from "components/jkt/global/truncateString";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";

const filter = createFilterOptions();

const FourthSection = () => {
  const [physicianData, setPhysicianData] = useState([]);
  const [isPhysicianNew, setIsPhysicianNew] = useState(false);
  const [selectedPhysicianId, setSelectedPhysicianId] = useState(null);
  const [selectedPhysicianCode, setSelectedPhysicianCode] = useState(null);
  const [showPhysicianEditModal, setShowPhysicianEditModal] = useState(false);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const inputs = useSelector((state) => state.inputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const separatedEmails = inputs.patientEmailForDisplay?.join(", ");
  const clientId = decodedUserDetails()?.clientId;
  const dispatch = useDispatch();
  const { clientDetails } = parsedClientDetails();
  const { user } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    //Search filter of physician input fields
    dispatch(
      handleChangeSavedFields({
        physicianName: e.target.value,
      })
    );
  };

  const handleOpenModal = () => {
    //Function for showing the create physician modal, this is implemented in physician's dropdown search input field when you type and auto suggests to add new patient
    setIsPhysicianNew(true);
  };

  const handleOpenEditModal = () => {
    //For showing the create or update physician modal
    if (multiStepFormInputs.providerId === null) {
      return setIsPhysicianNew(true);
    } else {
      return setShowPhysicianEditModal(true);
    }
  };

  const handleCloseModal = () => {
    //Function for closing physician modal
    setIsPhysicianNew(false);
    setShowPhysicianEditModal(false);
    setSelectedPhysicianId(null);
    setSelectedPhysicianCode(null);
  };

  const handleSelectPhysician = (selectedPhysician) => {
    //For selecting a physician, and populating the fields that needs that information
    const physicianFullName = `${selectedPhysician?.firstName} ${
      selectedPhysician?.lastName === null ? "" : selectedPhysician?.lastName
    }`;
    setSelectedPhysicianId(selectedPhysician.id);
    setSelectedPhysicianCode(selectedPhysician?.code);
    dispatch(
      handleChangeMultiFields({
        providerId: selectedPhysician.id,
      })
    );
    dispatch(
      handleChangePhysicianFields({
        physicianFullName: physicianFullName,
      })
    );
    dispatch(
      handleChangeSavedFields({
        physicianCode: selectedPhysician.id,
        physicianName: physicianFullName,
      })
    );
  };

  const handleFetchHealhcareProvidersLookup = () => {
    //Fetching of healthcare providers
    dispatch(
      getHealthcareProvidersLookUp({
        clientId: clientId,
      })
    ).then((response) => {
      const isFetchSuccessful = response?.payload?.success;
      if (isFetchSuccessful) {
        setPhysicianData(response.payload.data.items);
      }
    });
  };

  useEffect(() => {
    handleFetchHealhcareProvidersLookup(); //Invoking of get function
    // eslint-disable-next-line
  }, [dispatch, savedInputs.refetchData]);

  useEffect(() => {
    if (savedInputs.physicianName === "") {
      //Check if physician input field has been cleared, then also clear input fields that uses physician
      setSelectedPhysicianId(null);
      setSelectedPhysicianCode(null);
      dispatch(
        handleChangeMultiFields({
          providerId: null,
        })
      );
      dispatch(
        handleChangePhysicianFields({
          id: 0,
          firstName: "",
          physicianFullName: "",
        })
      );
      dispatch(
        handleChangeSavedFields({
          physicianCode: 0,
          physicianName: "",
        })
      );
    }
  }, [dispatch, savedInputs.physicianName]);

  return (
    <>
      <Grid rowSpacing={2} columnSpacing={4} sx={{ marginTop: 3 }} container>
        <Grid xs={12} sm={12} md={6} lg={4} xl={4} item>
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            {getTranslation("Bill type")}
          </Typography>
          <br />
          <TextField
            value={getTranslation("Bill to Client")}
            size="small"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "94%",
                lg: "94%",
                xl: "94%",
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "2px solid",
                  borderColor: "darkBlue.secondary",
                },
              },
              borderRadius: "5px",
              outline: "none",
              marginTop: "0.5rem",
            }}
            placeholder="Bill to client"
            id="outlined-basic"
            variant="outlined"
            inputProps={{
              sx: {
                "&::placeholder": {
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "black",
                },
                fontWeight: "bold",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowBackIosNewRoundedIcon
                    sx={{
                      transform: "rotate(-90deg)",
                      color: "lightBlue.main",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
          item
        >
          <Typography
            sx={{
              color: "darkBlue.main",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            variant="p"
          >
            {getTranslation("Prepared by")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "1.2rem",
              backgroundColor: "grayishBlue.main",
              width: "max-content",
              marginTop: "0.5rem",
              padding: "0.4rem",
            }}
            variant="h1"
          >
            {user?.displayName}
          </Typography>
        </Grid>
        <Grid
          sx={{ position: "relative" }}
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            {getTranslation("Physician name")}
          </Typography>
          <br />
          <Box
            sx={{
              display: "flex",
              gap: "0.3rem",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Autocomplete
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "15rem",
                  lg: "15rem",
                  xl: "15rem",
                },
              }}
              disableClearable
              clearOnBlur
              size="small"
              value={savedInputs.physicianName}
              options={physicianData}
              renderOption={(props, physician) => (
                <>
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                  >
                    <Popover
                      placement="rightTop"
                      autoAdjustOverflow
                      zIndex={99999}
                      content={`${physician.displayName}`}
                      title={getTranslation("Physician Details")}
                    >
                      <li
                        style={{
                          margin: "0.2rem",
                          backgroundColor:
                            physician.displayName.includes("Add new") ||
                            physician.displayName.includes("Tambah dokter")
                              ? "#c1dbff"
                              : "",
                        }}
                        {...props}
                      >
                        {truncateString(physician.displayName)}
                      </li>
                    </Popover>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <li
                      style={{
                        margin: "0.2rem",
                        backgroundColor: physician.displayName.includes(
                          "Add new"
                        )
                          ? "#c1dbff"
                          : "",
                      }}
                      {...props}
                    >
                      {physician.displayName.length > 22
                        ? physician.displayName.substring(0, 27) + "..."
                        : physician.displayName}
                    </li>
                  </Box>
                </>
              )}
              onChange={(_, newValue) => {
                if (typeof newValue === "string") {
                  setTimeout(() => {
                    handleOpenModal();
                  });
                } else if (newValue && newValue.inputValue) {
                  handleOpenModal();
                } else {
                  handleSelectPhysician(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    displayName: getTranslation("Add new physician") + "*",
                  });
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                if (option.displayName) {
                  return option.displayName;
                }
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "15rem",
                      lg: "15rem",
                      xl: "15rem",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "2px solid",
                        borderColor: "darkBlue.secondary",
                      },
                    },
                    borderRadius: "5px",
                    outline: "none",
                    marginTop: "0.5rem",
                  }}
                  onChange={handleChange}
                  value={savedInputs.physicianName}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <Box sx={{ overflow: "hidden", opacity: 0 }}>
                          {params.InputProps.endAdornment}
                        </Box>
                        <InputAdornment
                          sx={{ transform: "translateX(30px)" }}
                          position="end"
                        >
                          <SearchOutlinedIcon
                            sx={{
                              color: "lightBlue.main",
                              pointerEvents: "none",
                            }}
                          />
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              )}
            />
            <Tooltip
              title={
                multiStepFormInputs.providerId === null
                  ? getTranslation("Add new physician")
                  : getTranslation("Edit physician")
              }
              placement="right-end"
              TransitionComponent={Zoom}
              arrow
            >
              <Box
                sx={{
                  backgroundColor: "darkBlue.main",
                  padding: "0.7rem",
                  height: "2.55rem",
                  borderRadius: "0.3rem",
                  cursor: "pointer",
                  marginTop: "0.5rem",
                  transition: "0.3s ease-in-out",
                  ":hover": {
                    backgroundColor: "darkBlue.secondary",
                  },
                }}
                onClick={handleOpenEditModal}
              >
                {multiStepFormInputs.providerId === null ? (
                  <PersonAddOutlinedIcon
                    sx={{
                      fontSize: "20px",
                      marginBottom: "0.1rem",
                      color: "white",
                      transform: "translateY(-3px)",
                    }}
                  />
                ) : (
                  <EditOutlinedIcon
                    sx={{
                      fontSize: "20px",
                      marginBottom: "0.3rem",
                      color: "white",
                      transform: "translateY(-3px)",
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {clientDetails?.clientConfig?.allowEmailSendingOfRequest && (
        <Grid rowSpacing={3} sx={{ marginTop: 3 }} container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              {getGlobalTranslation("Email address")}
            </Typography>
            <br />
            {inputs?.patientEmailForDisplay?.length > 0 && (
              <Typography
                variant="p"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "grayishBlue.secondary",
                  padding: "0.25rem",
                  width: "100%",
                }}
              >
                {separatedEmails}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Modal
        closable
        onCancel={handleCloseModal}
        open={isPhysicianNew || showPhysicianEditModal}
        width={500}
        footer={null}
      >
        <CreateUpdatePhysicianModal
          showPhysicianEditModal={showPhysicianEditModal}
          handleCloseModal={handleCloseModal}
          selectedPhysicianId={selectedPhysicianId}
          physicianCode={selectedPhysicianCode}
          setPhysicianCode={setSelectedPhysicianCode}
        />
      </Modal>
    </>
  );
};

export default FourthSection;
