import { Grid, Button, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Button as BtnAntD, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateUser from "./createUpdateUser";
import useDebounce from "hooks/useDebounce";
import { useCurrentHeight } from "utils/getScreenSize";
import DirectionFilter from "./filterOptions/directionFilter";
import AssortedFilter from "./filterOptions/assortedFilter";
import StatusFilter from "./filterOptions/statusFilter";
import { getAllUsers, getAllClient } from "store";
import { useCallback, useEffect, useState } from "react";
import { EmptyData } from "../global/emptyData";
import RolesFilter from "./filterOptions/rolesFilter";
import ClientFilter from "./filterOptions/clientFilter";

const GetAllUserLists = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = useState("userName");
  const [sortDirection, setSortDirection] = useState(0);
  const [selectedUserStatus, setSelectedUserStatus] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [usersData, setUsersData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isForUpdate, setIsForUpdate] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(0);
  const [clientsData, setClientsData] = useState([]);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (getAllUsers.pending().type === "user/get-all-users/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllUsers({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: currentPage,
        sortField: sortStr,
        sortDirection: sortDirection,
        userStatus: selectedUserStatus,
        roles: selectedRoles,
        clientId: selectedClientId,
      })
    ).then((element) => {
      if (element.type === "user/get-all-users/fulfilled") {
        setTotal(element.payload.data.totalPages * pageSize);
        dispatch(
          getAllClient({
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "Id",
            sortDirection: 0,
          })
        ).then((valClient) => {
          setIsLoading(false);
          if (valClient.type === "clients/get-all-clients/fulfilled") {
            const newUserData = element.payload.data.items.map((userData) => {
              return {
                ...userData,
                clientName: valClient.payload.data.items.find(
                  (cl) => cl.id === userData.clientId
                )?.clientName,
              };
            });
            setClientsData(valClient.payload.data.items);
            setUsersData(newUserData);
          }
        });
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    currentPage,
    sortDirection,
    selectedUserStatus,
    selectedRoles,
    selectedClientId,
  ]);

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrentPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrentPage(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No User Detected"
      action={showCreateUpdate}
      btnTitle="Add New User"
      renderButton={true}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(0);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const handleChangeUserStatus = (value) => {
    setSelectedUserStatus(value);
  };

  const handleSelectRole = (value) => {
    setSelectedRoles(value);
  };

  const handleSelectClient = (value) => {
    setSelectedClientId(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      width: 100,
      render: (value, record) => (
        <div className="flex flex-col items-start justify-start">
          <p className="text-xs text-blue-900">{record?.displayName}</p>
          <p>{value}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 120,
    },
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
      width: 100,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      width: 150,
      render: (rec, row) => (
        <Box>
          {rec?.map((role) => (
            <Tag color="blue" key={role}>
              {role}
            </Tag>
          ))}
        </Box>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 50,
      render: (rec, row) => (
        <Tag color={row.isActive ? "success" : "red"}>
          {row.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 50,
      render: (rec, row) => (
        <>
          <BtnAntD
            size="small"
            type="text"
            style={{
              marginLeft: 2,
            }}
            icon={<EditIcon fontSize="inherit" />}
            className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
            onClick={() => {
              handleUpdate(row);
              showCreateUpdate();
            }}
          >
            Update
          </BtnAntD>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2}>
          <Input
            placeholder="Search User"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <AssortedFilter
            defaultValue={sortStr}
            handleChangeSortField={handleChangeSortField}
          />
          <DirectionFilter
            defaultValue={sortDirection}
            handleChangeSortDirection={handleChangeSortDirection}
          />
          <StatusFilter
            selectedUserStatus={selectedUserStatus}
            handleChangeUserStatus={handleChangeUserStatus}
          />
          <RolesFilter handleSelectRole={handleSelectRole} />
          <ClientFilter handleSelectClient={handleSelectClient} />
        </Grid>
        {usersData.length !== 0 ? (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New User
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={usersData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: currentPage,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateUser
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientData={clientsData}
      />
    </>
  );
};
export default GetAllUserLists;
