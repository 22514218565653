import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Tag,
  Select,
  Button as BtnAntD,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateClient from "./createUpdateClient";
import useDebounce from "hooks/useDebounce";
import useCheckPermission from "hooks/useCheckPermission";
import ClientDetailsModal from "./clientDetailsModal";
import { useCurrentHeight } from "utils/getScreenSize";
import { EmptyData } from "components/jkt/global/emptyData";
import {
  getAllClient,
  getAllClientType,
  getPhilippineAddressTypes,
  getAllBarangay,
  getAllCityMunicipalities,
  getProvinceLookUp,
  getCountryLookUp,
} from "store";

const GetAllClient = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Administration:Client:Modify"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("clientName");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [clientData, setClientData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  const [clientTypeData, setClientTypeData] = React.useState([]);
  const [addressType, setAddressType] = React.useState([]);
  const [barangay, setBarangay] = React.useState([]);
  const [cityMunicipality, setCityMunicipality] = React.useState([]);
  const [province, setProvince] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(null);
  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (getAllClient.pending().type === "clients/get-all-clients/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllClient({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (element.type === "clients/get-all-clients/fulfilled") {
        const formattedClientData = element?.payload?.data?.items?.map(
          (item) => ({
            ...item,
            enableCollectionSite: item?.clientConfig?.enableCollectionSite,
            enableProcessingWindowTime:
              item?.clientConfig?.enableProcessingWindowTime,
            enableVolumeCheck: item?.clientConfig?.enableVolumeCheck,
            inClientProcessingWindowTime:
              item?.clientConfig?.inClientProcessingWindowTime,
            registeredUserCap: item?.clientConfig?.registeredUserCap,
            allowEmailSendingOfRequest:
              item?.clientConfig?.allowEmailSendingOfRequest,
          })
        );
        setIsLoading(false);
        setClientData(formattedClientData);
        setTotal(element.payload.data.totalPages * pageSize);

        //LOAD CLIENT TYPE FOR LOOKUP
        dispatch(
          getAllClientType({
            search: "",
            pageSize: 999,
            pageNumber: 1,
            sortField: "typeName",
            sortDirection: 0,
          })
        ).then((elementClientType) => {
          if (
            elementClientType.type ===
            "clientTypes/get-all-client-types/fulfilled"
          ) {
            setClientTypeData(elementClientType.payload.data.items);
          }
        });

        //LOAD ADDRESS TYPE
        dispatch(getPhilippineAddressTypes()).then((element) => {
          if (
            element.type === "philippine-address/get-address-type/fulfilled"
          ) {
            setAddressType(element.payload.data);
          }
        });

        //LOAD BARANGAY
        dispatch(
          getAllBarangay({
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "Id",
            sortDirection: 0,
          })
        ).then((element) => {
          if (element.type === "barangay/get-all-barangay/fulfilled") {
            setBarangay(element.payload.data.items);
          }
        });

        //LOAD CITY MUNICIPALITY
        dispatch(
          getAllCityMunicipalities({
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "Id",
            sortDirection: 0,
          })
        ).then((element) => {
          if (
            element.type ===
            "citymunicipalities/get-all-citymunicipalities/fulfilled"
          ) {
            setCityMunicipality(element.payload?.data?.items);
          }
        });

        //LOAD PROVINCE
        dispatch(getProvinceLookUp()).then((element) => {
          if (element.type === "provinces/get-provinces-look-up/fulfilled") {
            setProvince(element.payload.data);
          }
        });

        dispatch(getCountryLookUp()).then((element) => {
          if (element.type === "countries/get-countries-look-up/fulfilled") {
            setCountry(element.payload.data);
          }
        });
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  //MODAL FOR INFO USER
  const [openInfo, setOpenInfo] = React.useState(false);
  const [selectedInfoDetails, setSelectedInfoDetails] = React.useState(null);

  const showModalInfo = () => {
    setOpenInfo(true);
  };
  const handleOkInfo = () => {
    setOpenInfo(false);
  };
  const handleCancelInfo = () => {
    setOpenInfo(false);
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Client Detected"
      action={showCreateUpdate}
      btnTitle="Add New Client"
      renderButton={accessRightsToModify}
    />
  );
  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };

  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };

  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
  };

  const handleUpdate = (rowVal) => {
    setIsForUpdate(true);
    setIdForUpdate(rowVal);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const renderBooleanValue = (value) => {
    return <Tag color={value ? "green" : "red"}>{value ? "Yes" : "No"}</Tag>;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "clientCode",
      key: "clientCode",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "clientName",
      key: "clientName",
      width: 300,
    },
    {
      title: "Enable Collection Site",
      dataIndex: "enableCollectionSite",
      key: "enableCollectionSite",
      width: 170,
      render: (rec) => {
        return renderBooleanValue(rec);
      },
    },
    {
      title: "Enable Volume Check",
      dataIndex: "enableVolumeCheck",
      key: "enableVolumeCheck",
      width: 170,
      render: (rec) => {
        return renderBooleanValue(rec);
      },
    },
    {
      title: "Enable Processing Window Time",
      dataIndex: "enableProcessingWindowTime",
      key: "enableProcessingWindowTime",
      width: 150,
      render: (rec) => {
        return renderBooleanValue(rec);
      },
    },
    {
      title: "Allow Email Sending of Request",
      dataIndex: "allowEmailSendingOfRequest",
      key: "allowEmailSendingOfRequest",
      width: 150,
      render: (rec) => {
        return renderBooleanValue(rec);
      },
    },
    {
      title: "Registered User Cap",
      dataIndex: "registeredUserCap",
      key: "registeredUserCap",
      width: 150,
    },
    {
      title: "Client Window Processing Time",
      dataIndex: "inClientProcessingWindowTime",
      key: "inClientProcessingWindowTime",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 80,
      render: (rec, row) => {
        const isUserActive = row.isActive;
        return (
          <Tag color={isUserActive ? "success" : "default"}>
            {isUserActive ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 190,
      render: (rec, row) => (
        <Space direction="horizontal" size={2}>
          <BtnAntD
            size="small"
            type="primary"
            style={{ background: "#1677FF", color: "white" }}
            icon={<InfoIcon fontSize="inherit" />}
            onClick={() => {
              showModalInfo();
              setSelectedInfoDetails(row);
            }}
          >
            Details
          </BtnAntD>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              icon={<EditIcon fontSize="inherit" />}
            >
              Update
            </BtnAntD>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Client"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "clientCode",
                label: "Code",
              },
              {
                value: "clientName",
                label: "Name",
              },
              {
                value: "isActive",
                label: "Status",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {clientData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Client
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={clientData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <ClientDetailsModal
        openInfo={openInfo}
        handleOkInfo={handleOkInfo}
        handleCancelInfo={handleCancelInfo}
        selectedInfoDetails={selectedInfoDetails}
        clientTypeData={clientTypeData}
      />
      <CreateUpdateClient
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientTypeData={clientTypeData}
        addressType={addressType}
        barangay={barangay}
        cityMunicipality={cityMunicipality}
        province={province}
        country={country}
      />
    </>
  );
};
export default GetAllClient;
