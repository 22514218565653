import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_ICD10CODES } from "../../config/connString";

const getIcd10CodesLookUp = createAsyncThunk(
  "icd10codes/get-icd10codes-look-up",
  async (params) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_ICD10CODES + "/icd10-codes-lookup", {
        params: {
          ICD10Code: params?.ICD10Code,
          Description: params?.Description,
          SearchTerm: params?.SearchTerm,
          PageSize: params?.PageSize,
          PageNumber: params?.PageNumber,
          SortOptions: params?.SortOptions,
          DateFrom: params?.DateFrom,
          DateTo: params?.DateTo,
          DateRangeProperty: params?.DateRangeProperty,
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getIcd10CodesLookUp };
