import { useCallback, useRef } from "react";

const useInfiniteScroll = ({ loading, hasNextPage, callbackFn }) => {
  const observer = useRef();
  const itemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer?.current) observer?.current?.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasNextPage) {
          callbackFn();
        }
      });

      if (node) observer?.current?.observe(node);
    },
    [loading, hasNextPage, callbackFn]
  );

  return {
    itemRef,
  };
};

export default useInfiniteScroll;
