import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styles from "components/jkt/testRequest/styles/createPatientModalStyles/styles.module.css";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPhysicians,
  createHealthcareProvider,
  updateHealthcareProvider,
} from "store";
import decodedUserDetails from "utils/decodedUserDetails";
import useFetchSpecificPhysician from "components/jkt/testRequest/proxies/useFetchSpecificPhysician";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import notification from "components/jkt/global/openNotification";
import { handleChangePhysicianFields } from "store/slice/physicianInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import useTranslation from "hooks/useTranslation";
import { convertedFullName } from "utils/convertedFullName";
import { checkedData } from "utils/checkedData";
import { parsedClientDetails } from "utils/parsedClientDetails";

const CreateUpdatePhysicianModal = ({
  handleCloseModal,
  showPhysicianEditModal,
  selectedPhysicianId,
  physicianCode,
  setPhysicianCode,
}) => {
  const clientId = decodedUserDetails()?.clientId;
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const physicianInputs = useSelector((state) => state.physicianInputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const { getTranslation, getGlobalTranslation } = useTranslation(
    "HealthcareProviderPage"
  );
  const { isClientKedoya } = parsedClientDetails();
  useFetchSpecificPhysician(selectedPhysicianId);
  const savedInputs = useSelector((state) => state.savedInputs);
  const inputHasError = physicianInputs.physicianFullName.trim().length === 0;

  const handleCloseFormModal = () => {
    //For closing physician's form modal
    handleCloseModal();
    setIsFormDirty(false);
  };

  const showErrorMessage = (inputName, displayName) => {
    if (!inputName && isFormDirty) {
      return (
        <Typography
          sx={{ color: "red", fontSize: "0.8rem", marginTop: "0.4rem" }}
          variant="p"
        >
          {displayName} {getGlobalTranslation("is required")}
        </Typography>
      );
    } else {
      return;
    }
  };

  const handleSubmit = () => {
    if (inputHasError) {
      //Form validation if both firstname and lastname has been filled, or it will throw an error
      return setIsFormDirty(true);
    }
    if (showPhysicianEditModal) {
      handleUpdatePhysician();
    } else {
      //Check if it will be a new physician
      if (
        createHealthcareProvider.pending().type ===
        "healthcareProviders/create-healthcare-provider/pending"
      ) {
        setIsLoading(true); //Check if the create action is processsing
      }
      const { firstName, lastName } = convertedFullName(
        physicianInputs.physicianFullName
      );

      dispatch(
        createHealthcareProvider({
          firstName: firstName,
          lastName: lastName,
          code: physicianCode,
        }) //Dispatch the create physician action
      ).then((res) => {
        if (res.payload.success) {
          //Update the UI and show success notification, and fill the search inputs that is displayed in the UI
          const responseData = res?.payload?.data;
          const physicianNameRes = `${responseData?.firstName} ${
            responseData?.lastName === null ? "" : responseData?.lastName
          }`;

          dispatch(
            handleChangeSavedFields({
              physicianCode: responseData?.id,
              physicianName: physicianNameRes,
              refetchData: !savedInputs.refetchData,
              isPhysicianNull: false,
            })
          );

          dispatch(
            handleChangePhysicianFields({
              id: responseData?.id,
              firstName: responseData?.firstName,
              lastName: checkedData(responseData?.lastName),
            })
          );

          dispatch(
            handleChangeMultiFields({
              providerId: responseData?.id,
            })
          );

          dispatch(
            getAllPhysicians({
              clientId: clientId,
              search: "",
              pageSize: 999,
              pageNumber: 1,
              sortField: "dateCreated",
              sortDirection: 1,
            })
          );

          notification.success({
            message: "Created Physician",
            description: `Successfully added ${physicianNameRes} to the list!`,
          });
          handleCloseModal();
          setIsLoading(false);
        }
        if (!res.payload.success) {
          //Check if there any error after the request, then show a toast notification to inform the user
          setIsLoading(false);
          notification.error({
            message: "Failed to create physician",
            description: errorMessagesDescription(
              res?.payload?.result?.value?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdatePhysician = () => {
    //For updating physicians data
    if (inputHasError) {
      //Form validation if both firstname and lastname has been filled, or it will throw an error
      return setIsFormDirty(true);
    }
    if (
      updateHealthcareProvider.pending().type ===
      "healthcareProviders/update-healthcare-provider/pending"
    ) {
      setIsLoading(true); //Check if the update action is processsing
    }

    const { firstName, lastName } = convertedFullName(
      physicianInputs.physicianFullName
    );

    dispatch(
      updateHealthcareProvider({
        id: multiStepFormInputs.providerId,
        firstName: firstName,
        lastName: lastName,
        code: physicianCode,
        isActive: true,
      }) //Dispatch the update physician action
    ).then((res) => {
      if (res.payload.success) {
        //Update the UI and show success notification, and fill the search inputs that is displayed in the UI
        const responseData = res?.payload?.data;
        const physicianNameRes = `${responseData?.firstName} ${checkedData(
          responseData?.lastName
        )}`;

        dispatch(
          handleChangeSavedFields({
            physicianCode: responseData?.id,
            physicianName: physicianNameRes,
            refetchData: !savedInputs.refetchData,
          })
        );
        notification.success({
          message: "Updated Physician",
          description: `Successfully updated ${physicianNameRes}`,
        });

        dispatch(
          handleChangePhysicianFields({
            ...physicianInputs,
            id: responseData?.id,
            firstName: responseData?.firstName,
            lastName:
              responseData?.lastName === null ? "" : responseData?.lastName,
          })
        );
        dispatch(
          handleChangeMultiFields({
            providerId: responseData?.id,
          })
        );
        dispatch(
          getAllPhysicians({
            clientId: clientId,
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "dateCreated",
            sortDirection: 1,
          })
        );

        handleCloseModal();
        setIsLoading(false);
      }
      if (!res.payload.success) {
        setIsLoading(false);
        notification.error({
          message: "Failed to update physician",
          description: errorMessagesDescription(
            res?.payload?.result?.value?.errorMessages
          ),
        });
      }
    });
  };

  useEffect(() => {
    //Check if the search input is empty or providerId is empty, then empty the physician form input fields
    if (multiStepFormInputs.providerId === null) {
      dispatch(
        handleChangePhysicianFields({
          id: 0,
          physicianFullName: "",
          firstName: "",
          lastName: "",
        })
      );
      setPhysicianCode("");
    }
  }, [dispatch, multiStepFormInputs.providerId]);

  return (
    <Box
      component="form"
      sx={{
        flexGrow: 1,
        padding: 3,
        width: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
        height: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
      }}
    >
      <Grid spacing={2} container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
            <Typography
              variant="h6"
              display="block"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              {showPhysicianEditModal
                ? getTranslation("Edit Physician")
                : getTranslation("Add Physician")}
            </Typography>
          </Divider>
        </Grid>
        {/* Physician Name */}
        <Grid
          columnSpacing={2}
          sx={{ marginTop: "0.7rem", marginBottom: "0.5rem" }}
          container
        >
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              {getTranslation("Physician Name")}
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              error={!physicianInputs.physicianFullName && isFormDirty}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.physicianFullName}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({
                    physicianFullName: e.target.value,
                  })
                )
              }
              autoComplete="false"
              placeholder={getGlobalTranslation("Enter your physician name")}
              size="small"
              variant="outlined"
            />
            {showErrorMessage(
              physicianInputs.physicianFullName,
              getTranslation("Physician Name")
            )}
          </Grid>
        </Grid>
        {/* Physician Code */}
        {isClientKedoya && (
          <Grid
            columnSpacing={2}
            sx={{ marginTop: "0.3rem", marginBottom: "0.5rem" }}
            container
          >
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <Typography
                variant="p"
                sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              >
                {getTranslation("Physician Code")}
              </Typography>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.secondary",
                    },
                  },
                }}
                className={styles["form-input"]}
                autoSave={false}
                value={physicianCode}
                onChange={(e) => setPhysicianCode(e.target.value)}
                autoComplete="false"
                placeholder={getGlobalTranslation("Enter your physician code")}
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* Footer buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          gap: "2rem",
          marginTop: "1.5rem",
        }}
      >
        <LoadingButton
          size="medium"
          disabled={isLoading}
          loading={isLoading}
          type="button"
          variant="contained"
          endIcon={showPhysicianEditModal ? <SaveIcon /> : <SendIcon />}
          onClick={handleSubmit}
        >
          {showPhysicianEditModal
            ? getGlobalTranslation("Save Changes")
            : getGlobalTranslation("Submit")}
        </LoadingButton>
        <Button
          size="medium"
          onClick={handleCloseFormModal}
          variant="dark"
          endIcon={<CloseIcon />}
          type="button"
        >
          {getGlobalTranslation("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdatePhysicianModal;
